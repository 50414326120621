#header {
  width: 19.2rem;
  height: 0.7rem;
  position: fixed;
  z-index: 999;
  top: 0;
  display: flex;
  align-items: center;
  border-bottom: solid rgba(255,255,255,0.05) 0.02rem;
  .logo-name{
    margin-left: 0.4rem;
    display: flex;
    flex-flow: row;
    img.logo{
      width: 0.87rem;
    }
    .name{
      min-width: 85px;
      margin-left: 0.2rem;
      font-size: 0.2rem;
      color: #ffffff;
    }
  }
  .navMeun{
    width: 5.39rem;
    margin-left: 7.25rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .to-like{
      font-size: 0.18rem;
      color: #ffffff;
      position: relative;
      cursor: pointer;
      display: inline-block;
      height: 0.7rem;
      line-height: 0.7rem;
      .pop-up{
        display: none;
        position: fixed;
        top: 0.72rem;
        right: 0.86+1.28rem;
        width: 6.53rem;
        line-height: 1;
        //height: 3.95rem;
        background-color: rgba(0,0,0,0.75);
        .product-img-box{
          display: flex;
          padding-bottom: 0.25rem;
          border-bottom: dashed 0.01rem #999999;
          .product-img-item{
            flex: 1;
            text-align: center;
            overflow: hidden;
            img{
              width: 0.7rem;
              height: 0.99rem;
              margin: 0.21rem auto 0.27rem;
            }
            span{display: block}
          }
        }
        .face-technology{
          padding: 0.24rem 0 0.23rem 0.32rem;
          .face-technology-title{
            display: block;
            font-size: 0.18rem;
            font-weight: normal;
            font-stretch: normal;
            //line-height: 0.36rem;
            letter-spacing: 0rem;
            color: #fefefe;
            padding-left: 0.09rem;
            margin-bottom: 0.2rem;
            border-left: solid 0.05rem #fd674d;
          }
          .face-technology-name-box{
            display: flex;
            flex-wrap: wrap;
            .face-technology-name{
              color: #fefefe;
              width: 2rem;
              display: flex;
              align-items: center;
              margin-bottom: 0.16rem;
              .exclusive{
                //width: 0.27rem;
                //height: 0.13rem;
                //line-height: 0.13rem;
                background-color: #fd674d;
                padding: 0.01rem 0.03rem 0.02rem 0.04rem;
                font-size: 0.1rem;
                font-weight: normal;
                font-stretch: normal;
                letter-spacing: 0rem;
                margin-left: 0.18rem;
                color: #fefefe;
                display: none;
              }
              &:nth-child(n+7){
                .exclusive{
                  display: block;
                }
              }
            }
          }
        }
      }
      &.productService:hover{
        .pop-up{
          display: block;
          animation-duration: .6s;
          animation-name: FadeIn;
          @keyframes FadeIn {
            from {
              opacity: 0;
              display: none;
            }

            to {
              opacity: 1;
              display: block;
            }
          }
        }
      }

      &.active::after{
        content: "";
        width: 100%;
        height: 0.02rem;
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: #fd674d;
      }
    }
  }
  .login-register{
    width: 1.06rem;
    height: 0.33rem;
    background-color: #fd674d;
    border-radius: 0.05rem;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-left: 0.58rem;
    .dividing-line{
      width: 1px;
      height: 0.16rem;
      background-color: #ffffff;
    }
    span{
      font-size: 0.16rem;
      cursor: pointer;
      a{
        color: #ffffff;
      }
    }
  }
  .language{
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-left: 0.29rem;
    width: 0.66rem;
    height: 0.33rem;
    background-color: #4d4d4d;
    border-radius: 0.04rem;
    opacity: 0.5;
    font-size: 0.18rem;
    color: #fefefe;
  }
  .tel{
    min-width: 120px;
    display: flex;
    align-items: center;
    img{
      display: inline-block;
      margin-right: 0.05rem;
    }
    margin-left: .25rem;
    font-size: 0.18rem;
    color: #fefefe;
  }
}
.dev-ext-container {
  position: fixed;
  right: 10px;
  width: 200px;
  font-size: 14px;
  color: #ff4a00;
  z-index: 100
}

.ext-contact,.ext-feedback,.ext-top {
  position: relative;
  margin: 5px;
  transition: all .3s ease;
  height: 50px;
  width: 50px;
  cursor: pointer;
  margin-left: 150px;
  text-indent: 70px;
  line-height: 50px;
  background: #f4f4f4;
  overflow: hidden;
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
  filter: gray
}

.ext-contact:hover,.ext-feedback:hover {
  -webkit-filter: grayscale(0);
  -moz-filter: grayscale(0);
  -ms-filter: grayscale(0);
  -o-filter: grayscale(0);
  filter: grayscale(0);
  filter: none;
  width: 150px;
  background-color: #fff3eb;
  margin-left: 50px;
  color: #ff4a00;
}

.ext-qrcode {
  position: relative;
  margin: 5px;
  height: 50px;
  width: 50px;
  cursor: pointer;
  margin-left: 150px;
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
  filter: gray
}

.ext-qrcode,.ext-qrcode .img-container {
  transition: all .3s ease;
  background: #f4f4f4
}

.ext-qrcode .img-container {
  position: absolute;
  left: -6px;
  top: 0;
  width: 0;
  padding-top: 18px;
  padding-bottom: 10px;
  text-align: center;
  font-size: 12px;
  color: #666;
  line-height: 18px;
  white-space: nowrap;
  overflow: hidden;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box
}

.ext-qrcode .img-container img {
  margin: 0 15px;
  width: 98px;
}

.ext-qrcode:hover {
  -webkit-filter: grayscale(0);
  -moz-filter: grayscale(0);
  -ms-filter: grayscale(0);
  -o-filter: grayscale(0);
  filter: grayscale(0);
  filter: none
}

.ext-qrcode:hover .img-container {
  width: 128px;
  left: -134px
}

.ext-top:hover {
  -webkit-filter: grayscale(0);
  -moz-filter: grayscale(0);
  -ms-filter: grayscale(0);
  -o-filter: grayscale(0);
  filter: grayscale(0);
  filter: none;
  background-color: #fff3eb
}

.ext-top {
  background: #f4f4f4 url("../../static/home/inbox-top.png") 14px no-repeat
}

.ext-qrcode {
  background: #f4f4f4 url("../../static/home/inbox-qr.png") 14px no-repeat
}

.ext-feedback {
  background: #f4f4f4 url("../../static/home/inbox-unread.png") 10px no-repeat;
}

.ext-contact {
  background: #f4f4f4 url("../../static/home/feedback.png") 10px no-repeat
}

.ext-qr {
  background: #f4f4f4 url("../../static/home/inbox-qr.png") 10px no-repeat
}

//.ext-inbox-entrance {
//  display: block;
//  background: #f4f4f4 url("../../static/home/inbox-unread.gif") no-repeat 50%;
//  position: relative;
//  margin: 5px;
//  height: 50px;
//  width: 50px;
//  cursor: pointer;
//  margin-left: 150px
//}
//
//.ext-inbox-unread-count {
//  background: #ed4747;
//  position: absolute;
//  top: 5px;
//  left: 24px;
//  padding: 0 5px;
//  text-indent: 0;
//  text-align: center;
//  line-height: 1.5;
//  font-size: 12px;
//  color: #fff
//}
