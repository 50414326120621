
/**
 * Eric Meyer's Reset CSS v2.0 (http://meyerweb.com/eric/tools/css/reset/)
 * http://cssreset.com
 */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header,
menu, nav, output, ruby, section, summary,
time, mark, audio, video, input {
  margin: 0;
  padding: 0;
  border: 0;
  //font-size: 100%;
  font-weight: normal;
  vertical-align: baseline;
  //font-family: Helvetica,arial,sans-serif;
}
img{display: block}
button{outline: none}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, menu, nav, section {
  display: block;
}
$buttonColor: #1ab394;
$bgColorfa: #fafafa;
$textColor3c: #3c3c3c;
$textColor999: #999999;
$textColorCcc: #cccccc;
$textColoreb9: #fd674d; // 旧#00ceb9;
body {
  min-width: 1024px;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* custom */

a {
  color: #ffffff;
  text-decoration:none;
  -webkit-backface-visibility: hidden;
}

i{font-style:normal}

li {
  list-style: none;
}

html,body {
}

body{
  width: 19.2rem;
  //height: 100vh;
  position: relative;
  font-family: Arial;
}
//img:not(.btn,.Bounced_Delete,.canvas_img,.skin_question_avatar_img){pointer-events:none};
::-webkit-scrollbar {
  display: none;
}
.line-clamp-six{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
}
.progress{
  background: #3c3c3c;
  //border:2px solid #3c3c3c;
  //border:2px solid #000;
  width: 5.250rem;
  height: 0.333rem;
  overflow: hidden;
  border-radius: 0.167rem;
  -webkit-appearance: none;
}
::-ms-fill{
  background: #fafafa;
}
::-moz-progress-bar{
  background: #fafafa;
}
::-webkit-progress-bar{
  background: #3c3c3c;
}
::-webkit-progress-value{
  background: #fafafa;
  border-radius: 0.167rem;
  overflow: hidden;
}
.homeLogo{width: 100%}
button{border: none}
