/**
 * Eric Meyer's Reset CSS v2.0 (http://meyerweb.com/eric/tools/css/reset/)
 * http://cssreset.com
 */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header,
menu, nav, output, ruby, section, summary,
time, mark, audio, video, input {
  margin: 0;
  padding: 0;
  border: 0;
  font-weight: normal;
  vertical-align: baseline; }

img {
  display: block; }

button {
  outline: none; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, menu, nav, section {
  display: block; }

body {
  min-width: 1024px; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/* custom */
a {
  color: #ffffff;
  text-decoration: none;
  -webkit-backface-visibility: hidden; }

i {
  font-style: normal; }

li {
  list-style: none; }

body {
  width: 19.2rem;
  position: relative;
  font-family: Arial; }

::-webkit-scrollbar {
  display: none; }

.line-clamp-six {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6; }

.progress {
  background: #3c3c3c;
  width: 5.250rem;
  height: 0.333rem;
  overflow: hidden;
  border-radius: 0.167rem;
  -webkit-appearance: none; }

::-ms-fill {
  background: #fafafa; }

::-moz-progress-bar {
  background: #fafafa; }

::-webkit-progress-bar {
  background: #3c3c3c; }

::-webkit-progress-value {
  background: #fafafa;
  border-radius: 0.167rem;
  overflow: hidden; }

.homeLogo {
  width: 100%; }

button {
  border: none; }

#footer {
  width: 19.2rem;
  overflow: hidden;
  background-color: #4d4d4d; }
  #footer .centent {
    margin: 0.3rem 0.55rem 0 0.96rem;
    display: flex; }
    #footer .centent .left {
      font-size: 0.22rem;
      color: #ffffff;
      width: 11.56rem;
      display: flex;
      justify-content: space-between; }
      #footer .centent .left .resources {
        display: flex;
        flex-flow: column; }
        #footer .centent .left .resources strong {
          font-size: 0.22rem;
          margin-bottom: 0.36rem; }
        #footer .centent .left .resources span {
          color: #999999;
          font-size: 0.16rem;
          margin-bottom: 0.23rem; }
    #footer .centent .right {
      display: flex;
      margin-left: 2.06rem; }
      #footer .centent .right .idankee-img, #footer .centent .right .public-img {
        width: 1.63rem;
        min-width: 96px;
        height: 1.87rem;
        display: flex;
        flex-flow: column; }
        #footer .centent .right .idankee-img img, #footer .centent .right .public-img img {
          width: 100%; }
        #footer .centent .right .idankee-img span, #footer .centent .right .public-img span {
          width: 100%;
          display: inline-block;
          text-align: center;
          font-size: 0.16rem;
          line-height: 0.36rem;
          color: #fefefe;
          opacity: 0.6; }
      #footer .centent .right .public-img {
        margin-left: 0.52rem; }
  #footer .right-reserved {
    border-top: solid 0.02rem #464545;
    text-align: center;
    font-size: 0.14rem;
    font-weight: normal;
    font-stretch: normal;
    line-height: 0.75rem;
    letter-spacing: 0rem;
    color: #ffffff;
    opacity: 0.6; }
