/**
 * Eric Meyer's Reset CSS v2.0 (http://meyerweb.com/eric/tools/css/reset/)
 * http://cssreset.com
 */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header,
menu, nav, output, ruby, section, summary,
time, mark, audio, video, input {
  margin: 0;
  padding: 0;
  border: 0;
  font-weight: normal;
  vertical-align: baseline; }

img {
  display: block; }

button {
  outline: none; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, menu, nav, section {
  display: block; }

body {
  min-width: 1024px; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/* custom */
a {
  color: #ffffff;
  text-decoration: none;
  -webkit-backface-visibility: hidden; }

i {
  font-style: normal; }

li {
  list-style: none; }

body {
  width: 19.2rem;
  position: relative;
  font-family: Arial; }

::-webkit-scrollbar {
  display: none; }

.line-clamp-six {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6; }

.progress {
  background: #3c3c3c;
  width: 5.250rem;
  height: 0.333rem;
  overflow: hidden;
  border-radius: 0.167rem;
  -webkit-appearance: none; }

::-ms-fill {
  background: #fafafa; }

::-moz-progress-bar {
  background: #fafafa; }

::-webkit-progress-bar {
  background: #3c3c3c; }

::-webkit-progress-value {
  background: #fafafa;
  border-radius: 0.167rem;
  overflow: hidden; }

.homeLogo {
  width: 100%; }

button {
  border: none; }

#index .swiper-slide {
  position: relative; }
  #index .swiper-slide .ad {
    position: absolute;
    top: 2.15rem;
    left: 0.73rem; }
    #index .swiper-slide .ad strong, #index .swiper-slide .ad span {
      display: block;
      font-weight: normal;
      letter-spacing: 0rem;
      color: #ececec; }
    #index .swiper-slide .ad strong {
      font-size: 0.6rem;
      font-stretch: normal;
      line-height: 0.8rem;
      margin-bottom: 0.13rem; }
    #index .swiper-slide .ad span {
      font-size: 0.3rem; }
  #index .swiper-slide img {
    width: 100%; }

#index .ai-detection-identification {
  background: #ffffff;
  padding-top: 0.79rem; }
  #index .ai-detection-identification .title {
    font-size: 0.4rem;
    font-weight: normal;
    font-stretch: normal;
    line-height: 0.36rem;
    letter-spacing: 0rem;
    color: #4d4d4d;
    text-align: center; }
  #index .ai-detection-identification .tab-nav {
    display: flex;
    justify-content: space-between;
    margin: 0.66rem auto 0;
    width: 11.07rem; }
    #index .ai-detection-identification .tab-nav .tab-item {
      width: 1.5rem;
      height: 1.68rem;
      border-radius: 0.3rem 0.3rem 0rem 0rem;
      text-align: center;
      overflow: hidden; }
      #index .ai-detection-identification .tab-nav .tab-item.active {
        background-color: rgba(240, 242, 245, 0.5);
        border-bottom: solid #fd674d 0.02rem; }
        #index .ai-detection-identification .tab-nav .tab-item.active span {
          color: #fd674d; }
      #index .ai-detection-identification .tab-nav .tab-item img {
        width: 0.8rem;
        margin: 0.26rem auto 0.16rem; }
      #index .ai-detection-identification .tab-nav .tab-item span {
        display: block;
        font-size: 0.18rem;
        font-weight: normal;
        font-stretch: normal;
        line-height: 0.36rem;
        letter-spacing: 0rem;
        color: #333333; }
  #index .ai-detection-identification .bottom {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 1.61rem 0;
    border-top: 0.02rem solid #eaeaea; }
    #index .ai-detection-identification .bottom .ai-detection-container {
      height: 5.89rem;
      padding: 0 0.25rem; }
      #index .ai-detection-identification .bottom .ai-detection-container .swiper-pagination-bullet {
        width: 0.1rem;
        height: 0.1rem;
        border-radius: 50%;
        background-color: #b0b0b0; }
      #index .ai-detection-identification .bottom .ai-detection-container .swiper-pagination-bullet-active {
        background-color: #fd674d; }
    #index .ai-detection-identification .bottom .bottom-item {
      width: 4.3rem;
      height: 4.85rem;
      padding: 0.12rem;
      background-color: #fefefe;
      border-radius: 0.2rem; }
      #index .ai-detection-identification .bottom .bottom-item:hover {
        box-shadow: 0rem 0.1rem 0.2rem 0rem rgba(31, 46, 87, 0.12); }
        #index .ai-detection-identification .bottom .bottom-item:hover button {
          color: #ffffff !important;
          background-color: #4d4d4d !important; }
          #index .ai-detection-identification .bottom .bottom-item:hover button i {
            background-image: url("../../static/home/Home_Enter-icon@2x.png") !important; }
      #index .ai-detection-identification .bottom .bottom-item button {
        border: 1px solid #4d4d4d; }
        #index .ai-detection-identification .bottom .bottom-item button i {
          background-image: url("../../static/home/Home_Enter-icon-active@2x.png") !important; }
      #index .ai-detection-identification .bottom .bottom-item img {
        width: 4.3rem;
        border-radius: 0.2rem; }
      #index .ai-detection-identification .bottom .bottom-item .name {
        display: flex;
        align-items: center; }
        #index .ai-detection-identification .bottom .bottom-item .name strong {
          flex: 1;
          display: block;
          font-size: 0.24rem;
          font-weight: normal;
          font-stretch: normal;
          line-height: 0.36rem;
          letter-spacing: 0rem;
          color: #333333;
          text-align: left;
          margin: 0.4rem auto 0.31rem; }
      #index .ai-detection-identification .bottom .bottom-item p {
        font-size: 0.2rem;
        font-weight: normal;
        font-stretch: normal;
        line-height: 0.36rem;
        letter-spacing: 0rem;
        color: #888888; }

#index .core-strengths {
  width: 19.2rem;
  height: 5.96rem;
  background-color: #eff3f5;
  overflow: hidden; }
  #index .core-strengths .title {
    text-align: center;
    font-size: 0.4rem;
    color: #4d4d4d;
    margin: 0.8rem auto 0.8rem; }
  #index .core-strengths .core-strengths-box {
    display: flex;
    justify-content: space-between;
    width: 13.27rem;
    margin: auto; }
    #index .core-strengths .core-strengths-box div.centent-box {
      width: 2.5rem;
      height: 3.0rem;
      background-image: linear-gradient(-1deg, #ffffff 65%, #eff3f5 100%), linear-gradient(#fefefe, #fefefe);
      background-blend-mode: normal, normal;
      box-shadow: 0rem 0.1rem 0.2rem 0rem rgba(28, 35, 58, 0.1);
      border-radius: 0.1rem;
      text-align: center; }
      #index .core-strengths .core-strengths-box div.centent-box .centent-img {
        width: 0.84rem;
        margin: -0.26rem auto 0; }
      #index .core-strengths .core-strengths-box div.centent-box .centent-name {
        font-size: 0.22rem;
        margin: 0.34rem auto 0.2rem;
        color: #2c3247; }
      #index .core-strengths .core-strengths-box div.centent-box .centent {
        margin: auto;
        width: 2.1rem;
        text-align: left;
        font-size: 0.14rem;
        font-weight: normal;
        font-stretch: normal;
        line-height: 0.24rem;
        letter-spacing: 0rem;
        color: #999999; }

#index .application-scenarios .top-title {
  height: 1.98rem;
  line-height: 1.98rem;
  text-align: center;
  font-size: 0.4rem;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0rem;
  color: #2c3247; }

#index .application-scenarios .bottom-centent {
  display: flex; }
  #index .application-scenarios .bottom-centent .bottom-item {
    flex: 1;
    height: 5.2rem;
    text-align: center;
    overflow: hidden;
    position: relative;
    background-size: 100% 100%;
    background-repeat: no-repeat; }
    #index .application-scenarios .bottom-centent .bottom-item:hover .mask, #index .application-scenarios .bottom-centent .bottom-item:hover .img, #index .application-scenarios .bottom-centent .bottom-item:hover .name, #index .application-scenarios .bottom-centent .bottom-item:hover .centent {
      display: block;
      animation-duration: .6s;
      animation-name: maskTop; }
    #index .application-scenarios .bottom-centent .bottom-item:hover .img {
      width: 0.7rem;
      height: 0.5622rem;
      margin: 1.01rem auto 0; }

@keyframes maskTop {
  from {
    top: 20%;
    opacity: 0.5; }
  to {
    opacity: 1;
    top: 0%; } }
    #index .application-scenarios .bottom-centent .bottom-item .mask {
      position: absolute;
      top: 0%;
      left: 0;
      opacity: 1;
      display: none;
      width: 100%; }
    #index .application-scenarios .bottom-centent .bottom-item .img {
      position: relative;
      z-index: 555;
      margin: 1.8rem auto 0;
      width: 1.06rem;
      height: 0.85rem; }
    #index .application-scenarios .bottom-centent .bottom-item .name {
      position: relative;
      z-index: 555;
      display: block;
      font-size: 0.36rem;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0rem;
      color: #ffffff;
      margin: 0.2rem auto; }
    #index .application-scenarios .bottom-centent .bottom-item .centent {
      display: none;
      position: relative;
      z-index: 555;
      font-size: 0.2rem;
      font-weight: normal;
      font-stretch: normal;
      line-height: 0.32rem;
      letter-spacing: 0rem;
      color: #ececec;
      width: 2.4rem;
      margin: auto; }

#index .adapt-scene .top-title {
  height: 2rem;
  line-height: 2.12rem;
  text-align: center;
  font-size: 0.4rem;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0rem;
  color: #2c3247; }

#index .adapt-scene .bottom-centent {
  display: flex;
  padding: 0 1.88rem; }
  #index .adapt-scene .bottom-centent .bottom-item {
    flex: 1;
    text-align: center; }
    #index .adapt-scene .bottom-centent .bottom-item img {
      width: 2.56rem;
      margin: auto; }
    #index .adapt-scene .bottom-centent .bottom-item .name {
      font-size: 0.32rem;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0rem;
      color: #333333; }
    #index .adapt-scene .bottom-centent .bottom-item .centent {
      margin: 0 auto 0.8rem;
      width: 3.16rem;
      font-size: 0.16rem;
      font-weight: normal;
      font-stretch: normal;
      line-height: 0.24rem;
      letter-spacing: 0rem;
      color: #333333; }

#index .partners {
  background-color: #eff3f5;
  padding-bottom: 0.8rem; }
  #index .partners .top-title {
    height: 1.98rem;
    line-height: 1.98rem;
    text-align: center;
    font-size: 0.4rem;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0rem;
    color: #3c3c3c; }
  #index .partners .bottom-centent img {
    width: 18rem;
    margin: auto; }

mark {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #111321;
  opacity: 0.8;
  display: inline-block;
  width: 100%;
  height: 100%; }
